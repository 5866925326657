import { Delete, DeleteOutline, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Stack, Typography } from '@mui/material'
import React from 'react'

const OneCate = ({ i, CON, hitAxios, getCates }) => {
    const [data, setData] = React.useState([])
    async function delOne(id) {
        if (window.confirm("Are you sure?")) {
            const res = await hitAxios({
                path: "/api/admin/del_upscale_cate",
                post: true,
                admin: true,
                obj: { id }
            })
            if (res.data.success) {
                getCates()
            }
        }
    }

    async function delImg(internal_image) {
        if (window.confirm("Are you sure?")) {
            const res = await hitAxios({
                path: "/api/admin/del_img",
                post: true,
                admin: true,
                obj: { internal_image }
            })
            if (res.data.success) {
                getImgs()
            }
        }
    }

    async function getImgs() {
        const res = await hitAxios({
            path: "/api/admin/get_imgs_cate_id",
            post: true,
            admin: false,
            obj: { id: i?.id }
        })
        if (res.data.success) {
            setData(res.data.data)
        }
    }

    React.useEffect(() => {
        if (i) {
            getImgs()
        }
    }, [i])

    return (
        <div>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} >
                    <Typography variant='body2' >{i?.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction={'row'} justifyContent={'flex-end'}>
                        <IconButton
                            onClick={() => delOne(i?.id)}
                            color='error' size='small' >
                            <DeleteOutline />
                        </IconButton>
                    </Stack>

                    <Box mt={2}>
                        <ImageList variant="masonry" cols={6} sx={{
                            columnCount: {
                                xs: '3 !important',
                                sm: '3 !important',
                                md: '3 !important',
                                lg: '6 !important',
                                xl: '7 !important',
                            },
                        }} gap={8}>
                            {data.map((item, key) => (
                                <ImageListItem key={key}>
                                    <img
                                        srcSet={`${item.external_image}`}
                                        src={`${item.external_image}`}
                                        alt={item.id}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={`${item?.width}x${item?.height}`}
                                        subtitle={item.model}
                                        actionIcon={
                                            <IconButton
                                                onClick={() => delImg(item?.internal_image)}
                                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                aria-label={`info about ${item.id}`}
                                            >
                                                <Delete />
                                            </IconButton>
                                        }
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default OneCate
import { Box, Container, Tab, Tabs, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';
import AiCategory from './ai-category/AiCategory';
import { GlobalContext } from '../../context/GlobalContext';
import UpscaleCategory from './upscale_category/UpscaleCategory';
import ImportImages from './import-images/ImportImages';
import SearchModel from './search-models/SearchModel';
import Menus from './menus/Menus';
import ManageUser from './mange-user/ManageUser';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AdminPanel = () => {
    const CON = React.useContext(GlobalContext)
    const { hitAxios } = React.useContext(GlobalContext)

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container maxWidth='lg'>
            <Box pt={4}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Dashboard" {...a11yProps(0)} />
                        <Tab label="Ai Category" {...a11yProps(1)} />
                        <Tab label="Upscale Category" {...a11yProps(2)} />
                        <Tab label="Import Images" {...a11yProps(3)} />
                        <Tab label="Search Model" {...a11yProps(4)} />
                        <Tab label="Menus" {...a11yProps(5)} />
                        <Tab label="Users" {...a11yProps(6)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    Dashboard
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <AiCategory hitAxios={hitAxios} CON={CON} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <UpscaleCategory hitAxios={hitAxios} CON={CON} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <ImportImages hitAxios={hitAxios} CON={CON} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                    <SearchModel hitAxios={hitAxios} CON={CON} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={5}>
                    <Menus hitAxios={hitAxios} CON={CON} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={6}>
                    <ManageUser hitAxios={hitAxios} CON={CON} />
                </CustomTabPanel>
            </Box>
        </Container>
    )
}

export default AdminPanel
import { TextField } from '@mui/material'
import React from 'react'

const TextFieldNew = ({ ...props }) => {
    return (
        <TextField
            {...props} size='small' InputProps={{
                style: {
                    borderRadius: 10
                }
            }} />
    )
}

export default TextFieldNew

import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../components/TextFieldNew'
import { CopyAll, LeakAdd } from '@mui/icons-material'

const SearchModel = ({ CON, hitAxios }) => {
    const [data, setData] = React.useState([])
    const [state, setState] = React.useState({
        limit: 1,
        query: ""
    })

    async function getModels() {
        const res = await hitAxios({
            path: `/api/admin/get_models?limit=${state.limit}&query=${state.query}`,
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_hamid")
        })
        if (res.data.success) {
            setData(res.data.data)
        }
    }

    return (
        <div>
            <Stack direction={'column'} spacing={2}>
                <Stack direction={'row'} spacing={1}>
                    <TextFieldNew
                        onChange={(e) => {
                            setState({ ...state, limit: e.target.value })
                        }}
                        label="limit" />
                    <TextFieldNew
                        onChange={(e) => {
                            setState({ ...state, query: e.target.value })
                        }}
                        label="query" />
                </Stack>
                <Stack direction={'row'}>
                    <Button
                        onClick={getModels}
                        startIcon={<LeakAdd />}
                        variant='contained'
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none'
                        }} >
                        Get Models
                    </Button>
                </Stack>

                <Stack mt={2} direction={'column'} spacing={2}>
                    {data.map((i, key) => {
                        return (
                            <Box key={key} bgcolor={'action.hover'} borderRadius={2} sx={{ padding: "0px 10px 0px 10px" }} >
                                <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography>{i?.name} || {i?.id}</Typography>
                                    <Tooltip title="Copy Model ID" >
                                        <IconButton
                                            onClick={() => {
                                                navigator.clipboard.writeText(i?.id)
                                            }}
                                            color='info' >
                                            <CopyAll />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </Box>
                        )
                    })}
                </Stack>
            </Stack>
        </div>
    )
}

export default SearchModel
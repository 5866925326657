import { Box, Button, ImageList, useMediaQuery, ImageListItem, ImageListItemBar, IconButton, ListSubheader, Stack, Dialog, Grid, Typography, Chip, CardMedia, TextField, InputAdornment } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../components/TextFieldNew'
import { AddOutlined, CloudDownload, Info, LeakAdd } from '@mui/icons-material'

const ImportImages = ({ CON, hitAxios }) => {
    const [data, setData] = React.useState([])
    const [both, setBoth] = React.useState({})
    const [state, setState] = React.useState({
        dialog: false,
        dialogJson: {}
    })

    async function importImg(id, url, prompt) {
        const res = await hitAxios({
            path: "/api/admin/import_img",
            post: true,
            admin: true,
            obj: { id, url, prompt }
        })
        if (res.data.success) {
            setState({ ...state, dialog: false })
        }
    }

    async function getCates() {
        const res = await hitAxios({
            path: "/api/admin/get_both_cate",
            post: false,
            admin: true,
            token_user: ""
        })
        if (res.data.success) {
            setBoth(res.data)
        }
    }

    React.useEffect(() => {
        getCates()
    }, [])

    async function getImages() {
        const queryParams = {
            limit: state.limit,
            modelId: state.modelId,
            sort: state.sort,
            period: state.period,
            page: state.page,
        };

        // Remove undefined values from queryParams
        Object.keys(queryParams).forEach((key) => queryParams[key] === undefined && delete queryParams[key]);

        const res = await hitAxios({
            path: `/api/admin/get_images?${new URLSearchParams(queryParams).toString()}`,
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_hamid")
        });

        if (res.data.success) {
            console.log(res.data.data)
            setData(res.data.data);
        }
    }
    return (
        <div>
            <Stack direction={'column'} spacing={2}>
                <Stack direction={'row'} spacing={1}>
                    <TextFieldNew
                        fullWidth
                        onChange={(e) => {
                            setState({ ...state, limit: e.target.value })
                        }}
                        label="limit" />

                    <TextFieldNew
                        fullWidth
                        onChange={(e) => {
                            setState({ ...state, modelId: e.target.value })
                        }}
                        label="modelId" />


                </Stack>

                <Stack direction={'row'} spacing={1}>
                    <TextFieldNew
                        fullWidth
                        helperText="(Most Reactions, Most Comments, Newest)"
                        onChange={(e) => {
                            setState({ ...state, sort: e.target.value })
                        }}
                        label="sort" />

                    <TextFieldNew
                        fullWidth
                        helperText="(AllTime, Year, Month, Week, Day)"
                        onChange={(e) => {
                            setState({ ...state, period: e.target.value })
                        }}
                        label="period" />

                    <TextFieldNew
                        fullWidth
                        onChange={(e) => {
                            setState({ ...state, page: e.target.value })
                        }}
                        label="page" />
                </Stack>

                <Stack direction={'row'}>
                    <Button
                        onClick={getImages}
                        startIcon={<LeakAdd />}
                        variant='contained'
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none'
                        }} >
                        Get Images
                    </Button>
                </Stack>

                <Box mt={4}>
                    <ImageList variant="masonry" cols={4} sx={{
                        columnCount: {
                            xs: '3 !important',
                            sm: '3 !important',
                            md: '3 !important',
                            lg: '4 !important',
                            xl: '5 !important',
                        },
                    }} gap={8}>
                        {data.map((item, key) => (
                            <ImageListItem key={key}>
                                <img
                                    srcSet={`${item.url}`}
                                    src={`${item.url}`}
                                    alt={item.id}
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    title={`${item?.width}x${item?.height}`}
                                    subtitle={item.model}
                                    actionIcon={
                                        <IconButton
                                            onClick={() => {
                                                setState({
                                                    ...state, dialog: true,
                                                    dialogJson: item
                                                })
                                            }}
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            aria-label={`info about ${item.id}`}
                                        >
                                            <CloudDownload />
                                        </IconButton>
                                    }
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>

                <TextField
                    onChange={(e) => setState({ ...state, source: e.target.value })}
                    label="Enter your source"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    onClick={() => {
                                        const id = prompt("Enter prompt id");
                                        const newPrompt = prompt("Enter prompt");
                                        if (id && newPrompt) { // Check both id and newPrompt before proceeding
                                            importImg(id, state.source, newPrompt); // Changed 'prompt' variable name to 'newPrompt' to avoid conflict with the prompt function
                                        }
                                    }}
                                    disabled={state.source ? false : true} // Simplified the condition
                                >
                                    <AddOutlined />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Stack>


            <Dialog fullWidth open={state.dialog} onClose={() => {
                setState({ ...state, dialog: false })
            }}>
                <Box p={2}>

                    <CardMedia
                        sx={{ borderRadius: 1 }}
                        component={'img'}
                        src={state?.dialogJson?.url} />

                    <Box p={1} borderRadius={2} bgcolor={'action.hover'}>
                        <Typography variant='caption' >{state?.dialogJson?.prompt}</Typography>
                    </Box>

                    <Grid container spacing={2} direction={'column'}>
                        <Grid item>
                            <Box p={1}>
                                <Typography variant='caption' color='gray' >UPSCALE</Typography>
                            </Box>
                            <Grid container direction={'row'} spacing={2} >
                                {both?.upscale_category?.map((i, key) => {
                                    return (
                                        <Grid key={key} item>
                                            <Chip icon={<IconButton
                                                onClick={() => importImg(i?.id, state?.dialogJson?.url, state?.dialogJson?.prompt)}
                                                size='small'>
                                                <CloudDownload />
                                            </IconButton>} label={i.title} />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Box p={1}>
                                <Typography variant='caption' color='gray' >AI AVATAR</Typography>
                            </Box>
                            <Grid container direction={'row'} spacing={2} >
                                {both?.ai_category?.map((i, key) => {
                                    return (
                                        <Grid key={key} item>
                                            <Chip icon={<IconButton
                                                onClick={() => importImg(i?.id, state?.dialogJson?.url, state?.dialogJson?.prompt)}
                                                size='small'>
                                                <CloudDownload />
                                            </IconButton>} label={i.title} />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </div>
    )
}

export default ImportImages
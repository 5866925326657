import { Button, Container, Grid, Stack, TextField } from '@mui/material'
import React from 'react'
import TextFieldNew from '../components/TextFieldNew'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { GlobalContext } from '../context/GlobalContext'

const AdminLogin = () => {
    const CON = React.useContext(GlobalContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const [state, setState] = React.useState({
    })

    const history = useHistory()
    async function handleLogin() {
        const resp = await hitAxios({
            path: "/api/admin/login",
            post: true,
            admin: false,
            obj: state
        })
        if (resp.data.success) {
            localStorage.setItem(process.env.REACT_APP_TOKEN + "_hamid", resp.data.token)
            history.push("/hamid")
        }
    }

    return (
        <Container maxWidth='xs'>
            <Grid container minHeight={'100vh'} alignItems={'center'} justifyContent={'center'}>
                <Grid item>
                    <Stack direction={'column'} spacing={2}>
                        <TextFieldNew
                            onChange={(e) => {
                                setState({ ...state, email: e.target.value })
                            }}
                            label="Email"
                        />
                        <TextFieldNew
                            onChange={(e) => {
                                setState({ ...state, password: e.target.value })
                            }}
                            label="Password"
                        />
                        <Button
                            onClick={handleLogin}
                            size='small'
                            variant='contained'
                            sx={{ borderRadius: 2 }}
                        >
                            Login
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    )
}

export default AdminLogin
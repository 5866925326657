import React from 'react'
import { Switch } from 'react-router-dom'
import { Alert, Snackbar, Box, Backdrop, CircularProgress } from '@mui/material'
import { GlobalContext } from './context/GlobalContext'
import PublicRoute from './routing/PublicRoute'
import AdminRoute from './routing/AdminRoute.js'
import FrontEnd from './frontend/FrontEnd';
import Error404 from './Error404.js';
import AdminLogin from './admin/AdminLogin.js'
import AdminPanel from './admin/panel/AdminPanel.js'

const HomeMainPage = () => {
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)

    return (
        <Box sx={{ position: 'relative' }} color='text.primary' minHeight={'100vh'} bgcolor='background.default'>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={GLOBAL_CONTEXT.data.loading}
            >
                {<Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>}
            </Backdrop>

            <Snackbar open={GLOBAL_CONTEXT.data.snack} autoHideDuration={4000} >
                <Alert severity={GLOBAL_CONTEXT.data.snack_success ? 'success' : 'error'}>{GLOBAL_CONTEXT.data.snack_msg}</Alert>
            </Snackbar>

            <div style={{
                opacity: GLOBAL_CONTEXT.data.loading ? 0.25 : 1,
                pointerEvents: GLOBAL_CONTEXT.data.loading ? "none" : "initial"
            }}>
                <Switch>
                    <PublicRoute path='/' exact component={FrontEnd} />
                    <PublicRoute path='/hamid/login' exact component={AdminLogin} />
                    <AdminRoute path='/hamid' exact component={AdminPanel} />
                    <PublicRoute path='*' component={Error404} />
                </Switch>
            </div>
        </Box>
    )
}

export default HomeMainPage


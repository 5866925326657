import { Divider, IconButton, InputAdornment, Stack, TextField } from '@mui/material'
import React from 'react'
import { Add } from '@mui/icons-material'
import OneCate from './OneCate'

const UpscaleCategory = ({ CON, hitAxios }) => {
    const [state, setState] = React.useState({})
    const [cats, setCats] = React.useState([])

    async function getCates() {
        const res = await hitAxios({
            path: "/api/admin/get_upscale_category",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setState({ ...state, title: "" })
            setCats(res.data.data)
        }
    }

    React.useEffect(() => {
        getCates()
    }, [])

    async function hangleAdd() {
        const res = await hitAxios({
            path: "/api/admin/add_upscale_category",
            post: true,
            admin: true,
            obj: state
        })
        if (res.data.success) {
            getCates()
        }
    }
    return (
        <Stack direction={'column'} spacing={3}>
            <TextField
                value={state.title}
                onChange={(e) => setState({ ...state, title: e.target.value })}
                size='small'
                InputProps={{
                    style: {
                        borderRadius: 10
                    },
                    endAdornment: (<InputAdornment position='end'>
                        <IconButton onClick={hangleAdd} >
                            <Add />
                        </IconButton>
                    </InputAdornment>)
                }}
                label="Category Name" fullWidth />

            <Divider />

            <Stack direction={'column'} spacing={2}>
                {cats.map((i, key) => {
                    return (
                        <OneCate getCates={getCates} key={key} i={i} hitAxios={hitAxios} CON={CON} />
                    )
                })}
            </Stack>
        </Stack >
    )
}

export default UpscaleCategory
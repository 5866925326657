import React from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import { Delete, Send } from '@mui/icons-material';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <Box p={2} width={'100%'}  >
                <Grid container justifyContent={'space-between'} spacing={2}>
                    <Grid item>
                        <Typography variant='h5' >All users</Typography>
                    </Grid>
                    <Grid item>
                        <GridToolbarExport />
                    </Grid>
                </Grid>
            </Box>
        </GridToolbarContainer>
    );
}

const ManageUser = ({ CON, hitAxios }) => {
    const [data, setData] = React.useState([])

    async function getUsers() {
        const res = await hitAxios({
            path: "/api/admin/get_users",
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_hamid")
        })
        if (res.data.success) {
            setData(res.data.data)
        }
    }

    async function delUser(id) {
        const res = await hitAxios({
            path: "/api/admin/del_user",
            post: true,
            admin: true,
            obj: { id }
        })
        if (res.data.success) {
            getUsers()
        }
    }

    async function sendNoti(oneId, title, body) {
        const res = await hitAxios({
            path: "/api/admin/send_to_user",
            post: true,
            admin: true,
            obj: { oneId, title, body }
        })
    }

    React.useEffect(() => {
        getUsers()
    }, [])
    return (
        <div>
            {data ?
                <Box height='80vh'>
                    <DataGrid
                        getRowId={(row) => row.id}
                        rows={[...data].reverse()}
                        columns={[
                            { headerName: "ID", field: 'id', flex: 1 },
                            { headerName: "UID", field: 'uid', flex: 1 },
                            {
                                headerName: "Last Access", field: 'last_access', flex: 1, renderCell: data => {
                                    return (
                                        data.row.last_access ? moment.unix(data.row.last_access / 1000).fromNow() : "NA"
                                    )
                                }
                            },
                            {
                                headerName: "One ID", field: 'one_signal', flex: 1
                            },
                            {
                                headerName: "Version", field: 'app_installed_version', flex: 1
                            },
                            {
                                headerName: "Send Something", field: 'send', flex: 1, renderCell: data => {
                                    return (
                                        <IconButton onClick={() => {
                                            const title = prompt("Enter Title")
                                            const body = prompt("Enter Body")
                                            sendNoti(data.row.one_signal, title, body)
                                        }} >
                                            <Send />
                                        </IconButton>
                                    )
                                }
                            },
                            {
                                headerName: "Delete", field: 'del', flex: 1, renderCell: data => {
                                    return (
                                        <IconButton onClick={() => {
                                            if (window.confirm("Are you sure?")) {
                                                delUser(data.row.id)
                                            }
                                        }} color='error'>
                                            <Delete />
                                        </IconButton>
                                    )
                                }
                            }
                        ]}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        checkboxSelection={false}
                        components={{
                            Toolbar: CustomToolbar
                        }}
                        sx={{
                            boxShadow: 0,
                            borderRadius: 4,
                            border: 0,
                            fontSize: 13,
                            padding: 2
                        }}
                    />
                </Box> : ""
            }
        </div>
    )
}

export default ManageUser
import { Box, Button, Dialog, Divider, IconButton, Stack, Switch, Typography } from '@mui/material';
import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TextFieldNew from '../../../components/TextFieldNew';
import { Delete } from '@mui/icons-material';
import moment from 'moment'

const Menus = ({ hitAxios, CON }) => {
    const [data, setData] = React.useState([])
    const [state, setState] = React.useState({
        is_link: false
    })
    async function getMenu() {
        const res = await hitAxios({
            path: "/api/admin/get_menus",
            post: false,
            admin: true,
            token_user: ""
        })
        if (res.data.success) {
            setData(res.data.data)
        }
    }

    async function addMenu() {
        const res = await hitAxios({
            path: "/api/admin/add_menu",
            post: true,
            admin: true,
            obj: state
        })
        if (res.data.success) {
            getMenu()
            setState({ ...state, dialog: false })
        }
    }

    async function delMenu(id) {
        if (window.confirm("Are you sure?")) {
            const res = await hitAxios({
                path: "/api/admin/del_menu",
                post: true,
                admin: true,
                obj: { id }
            })
            if (res.data.success) {
                getMenu()
            }
        }
    }

    React.useEffect(() => {
        getMenu()
    }, [])

    return (
        <div>
            <Stack direction={'column'} spacing={4}>
                <Stack direction={'row'} justifyContent={'flex-end'}>
                    <Button onClick={() => setState({ ...state, dialog: true })} variant='contained' sx={{ borderRadius: 2 }} >
                        Add New
                    </Button>
                </Stack>

                <Dialog fullWidth open={state.dialog} onClose={() => setState({ ...state, dialog: false })}>
                    <Box p={4}>
                        <Stack direction={'column'} spacing={2}>
                            <Typography>Add New</Typography>

                            <TextFieldNew
                                label="Title"
                                onChange={(e) => setState({ ...state, title: e.target.value })}
                            />

                            <TextFieldNew
                                label="Link"
                                helperText="optional"
                                onChange={(e) => setState({ ...state, link: e.target.value })}
                            />

                            <ReactQuill
                                style={{ color: 'black', backgroundColor: "white", }}
                                theme="snow" onChange={(e) => setState({ ...state, content: e })} />

                            <Switch
                                onChange={(e) => setState({ ...state, is_link: e.target.checked })}
                                checked={state.is_link} />

                            <Button
                                onClick={addMenu}
                                variant='contained'
                                sx={{ borderRadius: 2 }}
                            >Add This</Button>
                        </Stack>
                    </Box>
                </Dialog>

                <Divider />

                <Stack direction={'column'} spacing={2}>
                    {data.map((i, key) => {
                        return (
                            <Box key={key} bgcolor={'action.hover'} borderRadius={2} p={3}>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Stack direction={'column'}>
                                        <Typography>{i?.title}</Typography>
                                        <Typography variant='body2' color={'gray'}>{moment(i?.createdAt).fromNow()}</Typography>

                                        {i?.is_link ? <Typography component='pre' variant='body2' color={'gray'}>🔗 {i?.link}</Typography> : null}                                    </Stack>

                                    <IconButton
                                        onClick={() => delMenu(i?.id)}
                                        color='error' >
                                        <Delete />
                                    </IconButton>
                                </Stack>
                            </Box>
                        )
                    })}
                </Stack>
            </Stack>
        </div>
    )
}

export default Menus